.cdx-marker {
    background: rgba(245,235,111,0.29);
    padding: 3px 0;
}


.editorjs-block {
    margin-bottom: 10px;
}

.editorjs {
    background: orangered;
}
